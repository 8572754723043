var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex align-center justify-space-between"},[_c('h3',[_vm._v("Palabras claves")]),_c('v-btn',{staticClass:"text-none elevation-0",attrs:{"color":"secondary","dark":""},on:{"click":function($event){return _vm.openConferenceKeywordModal()}}},[_vm._v(" Crear palabra clave ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-text-field',{attrs:{"label":"Buscar","outlined":"","dense":"","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchConferenceKeywords($event)}},model:{value:(_vm.conferenceKeywordParams.filter),callback:function ($$v) {_vm.$set(_vm.conferenceKeywordParams, "filter", $$v)},expression:"conferenceKeywordParams.filter"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-end"},[_c('v-btn',{staticClass:"white--text text-none elevation-0 mr-2",attrs:{"color":"primary"},on:{"click":_vm.searchConferenceKeywords}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" fa-search ")]),_vm._v(" Buscar ")],1),_c('v-btn',{staticClass:"text-none elevation-0",attrs:{"color":"default"},on:{"click":_vm.cleanConferenceKeywords}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" fa-broom ")]),_vm._v(" Limpiar ")],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('data-table',{ref:"conferenceKeywords",attrs:{"params":_vm.conferenceKeywordParams,"url":_vm.conferenceKeywordsUrl,"headers":_vm.conferenceKeywordsHeaders},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"ma-0",attrs:{"color":"accent","dense":"","hide-details":""},on:{"change":function($event){return _vm.activateConferenceKeyword(item)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 elevation-0",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onEditKeyword(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","dark":"","small":""}},[_vm._v(" fas fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar salon")])]),(!item.isUsed)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 elevation-0",attrs:{"outlined":"","small":"","color":"error"},on:{"click":function($event){return _vm.onRemoveConferenceKeyword(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","dark":"","small":""}},[_vm._v(" fas fa-trash-alt ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar palabra clave")])]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex align-center justify-space-between"},[_c('h3',[_vm._v("Salones")]),_c('v-btn',{staticClass:"text-none elevation-0",attrs:{"color":"secondary","dark":""},on:{"click":function($event){return _vm.openConferenceRoomModal()}}},[_vm._v(" Crear salon ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('data-table',{ref:"conferenceRooms",attrs:{"params":_vm.conferenceRoomParams,"url":_vm.conferenceRoomsUrl,"headers":_vm.conferenceRoomsHeaders},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"ma-0",attrs:{"color":"accent","dense":"","hide-details":""},on:{"change":function($event){return _vm.activateConferenceRoom(item)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 elevation-0",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onEditRoom(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","dark":"","small":""}},[_vm._v(" fas fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar salon")])]),(!item.isReserved)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 elevation-0",attrs:{"outlined":"","small":"","color":"error"},on:{"click":function($event){return _vm.onRemoveConferenceRoom(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","dark":"","small":""}},[_vm._v(" fas fa-trash-alt ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar salon")])]):_vm._e()]}}])})],1)]),_c('conference-keyword-modal',{ref:"conferenceKeywordModal"}),_c('conference-room-modal',{ref:"conferenceRoomModal"}),_c('confirm-dialog',{attrs:{"title":'Confirmación',"description":'¿Deseas eliminar la palabra clave?',"is-active":_vm.isConfirmConferenceKeywordActive},on:{"onClose":function($event){_vm.isConfirmConferenceKeywordActive = false},"onConfirm":function($event){return _vm.deleteConferenceKeyword()}}}),_c('confirm-dialog',{attrs:{"title":'Confirmación',"description":'¿Deseas eliminar el salon?',"is-active":_vm.isConfirmConferenceRoomActive},on:{"onClose":function($event){_vm.isConfirmConferenceRoomActive = false},"onConfirm":function($event){return _vm.deleteConferenceRoom()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }