<template>
  <v-row justify="center">
    <v-dialog v-model="isOpen" max-width="1000px" persistent scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">{{ isEdit ? 'Editar' : 'Crear' }} Salon
          </span>
        </v-card-title>
        <v-card-text>
          <span>
            Ingrese el nombre del salon y la capacidad separada por un guion, ejemplo: Salon 1 - Capacidad 50.
          </span>
        </v-card-text>
        <v-card-text>
          <validation-observer ref="form">
            <div class="row">
              <div class="col-12 pb-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Salon"
                  vid="name"
                  rules="required|max:80"
                >
                  <v-text-field
                    v-model="model.name"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Salon"
                    required
                  />
                </validation-provider>
              </div>
            </div>
            <div class="row" v-for="(schedule,index) in model.schedule" :key="index">
              <div class="col-6 py-0">
                <ValidationProvider
                  name="Fecha"
                  :vid="'Fecha'+index"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <date-picker :disabled="schedule.isReserved" label="Fecha" :hide-details="false" v-model="schedule.availableDate"
                               :errors="errors"></date-picker>
                </ValidationProvider>
              </div>
              <div class="col-6 py-0 d-flex">
                <ValidationProvider
                  class="flex-fill"
                  name="Horario"
                  :vid="'Horario'+index"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    v-model="schedule.times"
                    :items="getTimes(schedule.times)"
                    @change="onChangeTime(schedule)"
                    outlined
                    return-object
                    dense
                    chips
                    item-text="description"
                    item-value="time"
                    :error-messages="errors"
                    small-chips
                    label="Horarios"
                    multiple
                  ></v-autocomplete>
                </ValidationProvider>

                <v-btn v-if="!schedule.isReserved" color="primary" class="ml-2" @click="deleteSchedule(index)">
                  <v-icon
                    center
                    dark
                    size="15">
                    fa-trash
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <div class="row">
              <div class="col-12 py-0">
                <v-btn color="secondary" class="mr-2 text-none elevation-0" @click="addSchedule">
                  Agregar
                </v-btn>
              </div>
            </div>

            <div class="row">
              <div class="col-6 py-0 pl-4">
                <v-switch color="accent" hide-details v-model="model.isActive" label="¿Activa?"/>
              </div>
            </div>

          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveConferenceKeyword()">
            Aceptar
          </v-btn>
          <v-btn color="red" text @click="onClose">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import crudServiceMixin from '../../../../../../mixins/crudServiceMixin'
import formOperationsMixin from '../../../../../../mixins/formOperationsMixin'
import notificationMixin from '../../../../../../mixins/notificationMixin'
import loaderMixin from '../../../../../../mixins/loaderMixin'
import DatePicker from '../../../../../../components/date-picker/DatePicker'
import moment from 'moment'
import { CONFERENCE_ROOMS_URL } from '../../../../../../constants/ServicesConstants'

export default {
  components: { DatePicker },
  data () {
    return {
      isEdit: false,
      isOpen: false,
      model: {
        id: null,
        name: null,
        isActive: true,
        fairId: null,
        schedule: [{
          availableDate: null,
          isReserved: false,
          times: [],
          originalTimes: [],
          deletedTimes: []
        }],
        deletedSchedule: []
      }
    }
  },
  methods: {
    addSchedule () {
      this.model.schedule.push({
        availableDate: null,
        times: [],
        originalTimes: [],
        deletedTimes: []
      })
    },
    deleteSchedule (index) {
      const schedule = this.model.schedule[index]
      if (schedule && schedule.id && !schedule.isReserved) {
        this.model.deletedSchedule.push(schedule)
      }
      this.model.schedule.splice(index, 1)
    },
    getTimes (selectedTimes) {
      const times = []
      for (let i = 6; i < 21; i++) {
        let time = i.toString().length === 2 ? `${i}:00:00` : `0${i}:00:00`
        let description = moment(time, 'HH:mm').format('hh:mm A')
        let selectedTime = selectedTimes.find(time => time.description === description)
        times.push({
          description,
          time,
          disabled: (selectedTime) ? selectedTime.isReserved : false
        })
        time = i.toString().length === 2 ? `${i}:30:00` : `0${i}:30:00`
        description = moment(time, 'HH:mm').format('hh:mm A')
        selectedTime = selectedTimes.find(time => time.description === description)
        times.push({
          description,
          time,
          disabled: (selectedTime) ? selectedTime.isReserved : false
        })
      }
      return times
    },
    async open (fairId, room) {
      if (room) {
        this.model.id = room.id
        this.model.name = room.name
        this.model.isActive = room.isActive
        this.model.fairId = room.fairId
        this.model.schedule = [...room.schedule]
        this.model.deletedSchedule = []

        for (const schedule of this.model.schedule) {
          schedule.originalTimes = [...schedule.times]
          schedule.deletedTimes = []
        }

        this.isEdit = true
      } else {
        this.model.fairId = fairId
        this.isEdit = false
      }
      this.isOpen = true
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    onChangeTime (schedule) {
      const timesDescriptions = schedule.times.map(time => time.description)
      const originalTimesDescriptions = schedule.originalTimes.map(originalTime => originalTime.description)
      const deletedDescriptions = originalTimesDescriptions.filter(description => !timesDescriptions.includes(description))

      for (const deletedDescription of deletedDescriptions) {
        const originalTime = schedule.originalTimes.find(originalTime => originalTime.description === deletedDescription)

        if (originalTime) {
          const index = schedule.deletedTimes.findIndex(deletedTime => deletedTime.id === originalTime.id)
          if (index === -1) {
            schedule.deletedTimes.push(originalTime)
          }
        }
      }
    },
    onClose () {
      this.isOpen = false
      this.onClear()
      this.rejectPromise()
    },
    onClear () {
      this.model.name = null
      this.model.fairId = null
      this.model.schedule = [{
        availableDate: null,
        times: [],
        originalTimes: [],
        deletedTimes: []
      }]
      this.$refs.form.reset()
    },
    saveConferenceKeyword () {
      this.executeWithFormValidation(() => {
        if (this.isEdit) {
          this.update(CONFERENCE_ROOMS_URL + '/' + this.model.id, this.model).then(() => {
            this.onClear()
            this.isOpen = false
            this.resolvePromise()
          })
        } else {
          this.create(CONFERENCE_ROOMS_URL, this.model).then(() => {
            this.onClear()
            this.isOpen = false
            this.resolvePromise()
          })
        }
      })
    }
  },
  mixins: [
    crudServiceMixin,
    formOperationsMixin,
    notificationMixin,
    loaderMixin
  ],
  name: 'ConferenceRoomModal'
}
</script>
